import React, { useState, SyntheticEvent } from 'react';
import                                      './Testata.css';
import { AddIcon, PercentageIcon, EuroIcon, CopyIcon, XMarkIcon }   from '../../../../../assets/icons/icons';
import {
    AutocompleteClientsAgentsObject
}                                           from '../../../../../utils/ConfigInterfaces';
import { testNumberInputPercentagesTwoDecimals }                from '../../../../../utils/CommonUtilities';
import TextField                            from '@mui/material/TextField';
import IconButton                           from '@mui/material/IconButton';
import Autocomplete                         from '@mui/material/Autocomplete';
import FormControlLabel                     from '@mui/material/FormControlLabel';
import Checkbox                             from '@mui/material/Checkbox';
import FormGroup                            from '@mui/material/FormGroup';
import { LocalizationProvider }             from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }                    from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }                       from '@mui/x-date-pickers/DatePicker';
import moment                               from 'moment';
import 'moment/locale/it';
import AddEntryDialog from './AddEntryDialog';
import { inputOnlyNumbersWithComma } from '../../../../../utils/CommonUtilities';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function Testata(props:any) {
    const { loading, setLoading } = props;

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogParams, setDialogParams] = useState({});

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const openInsDialog = (params:any) => {
        setDialogParams(params);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleChange = (source:string, newValue:any) => {
        if(source === 'cliente'){
            if(!props.kAgenziaSelected){
                props.set_kSoggFattSelected(newValue?.id);
                props.setTipoSoggFattSelected(newValue?.TYPE);
            }
            props.set_kClienteSelected( newValue?.id );
        }else if(source === 'agenzia'){
            props.set_kAgenziaSelected( newValue?.id ); 
            props.setDn(newValue?.DN_PERC || ''); 
            props.setDnOriginaleAgenzia(newValue?.DN_PERC || ''); 
            if(newValue===null){
                props.set_kSoggFattSelected(props.kClienteSelected); 
                props.setTipoSoggFattSelected('CL'); 
            }else{
                props.set_kSoggFattSelected(newValue?.id); 
                props.setTipoSoggFattSelected(newValue?.TYPE); 
            }
        }else if(source === 'settore'){
            if(newValue === null){
                props.setKCategoria('');
                props.setCategorie([]);
            }else{
                props.getCategorie(newValue?.id);
            }
            props.setKSettore(newValue?.id)
        }
    }
 
    const handleCopyClipboard = (event: React.MouseEvent<HTMLElement>) => {
        let selectedRefProg = props.referentiProgetto.find( (o:any) => o.id === props.kReferenteProgetto );
        if(selectedRefProg){
            let refProgEmail = selectedRefProg.label.split(' - ')[1];
            navigator.clipboard.writeText(refProgEmail);
            setAnchorEl(event.currentTarget);
            setOpen(true);
            setTimeout(() => {setOpen(false)}, 2000);
        }
    }

    if(!loading) {
        return (
            <div className='proposta-testata'>
                <div className='details-area'>
                    <div className='testata-wrapper'>
                        <div className='infos-wrapper'>
                            <div className='flex-row'>
                                <TextField
                                    id                   = "standard-basic"
                                    label                = "Descrizione Proposta"
                                    variant              = "standard"
                                    value                = {props.dProposta}
                                    onChange             = { (event) => props.setDProposta( event.target.value ) }
                                    inputProps           = {{maxLength: 50}}
                                    disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                />
                                
                                <FormControl variant="standard" className='no-margin select-remove-button-option' sx={{ minWidth: 120 }}>
                                    <InputLabel id="dipartimentoLabel">Dipartimento</InputLabel>
                                    <Select
                                        labelId="dipartimentoLabel"
                                        id="dipartimentoSelect"
                                        value={props.kDipartimento}
                                        onChange={(event) => props.setKDipartimento(event.target.value)}
                                        label="Dipartimento"
                                        endAdornment={props.kDipartimento&&(<IconButton className='remove-option-button-select' onClick={() => props.setKDipartimento('')}><XMarkIcon addClass='font-16'/></IconButton>)}
                                        /* disabled         = {props.proposalTotalDisabled} */
                                    >
                                        {props.dipartimenti.map((dip:any) => <MenuItem key={dip.KDIPARTIMENTO} value={dip.KDIPARTIMENTO}>{dip.DDIPARTIMENTO}</MenuItem>)}
                                    </Select>
                                </FormControl>

                                <LocalizationProvider dateAdapter={AdapterMoment} key="data-proposta">
                                    <DatePicker
                                        label            = "Data Proposta"
                                        format           = "DD/MM/YYYY"
                                        views            = {['year', 'month', 'day']}
                                        value            = { moment( props.dataProp, 'YYYYMMDD' ) }
                                        onChange         = { ( value ) => {props.setDataProp( moment(value).format('YYYYMMDD') )} }
                                        slotProps        = {{ textField: { variant: 'outlined' } }}
                                        className        = 'TextFieldDate smaller-date-field'
                                        disabled         = {props.proposalTotalDisabled}
                                    />
                                </LocalizationProvider>

                                <TextField
                                    id                   = "kProposta"
                                    label                = "ID Proposta"
                                    className            = 'id-proposta'
                                    variant              = "standard"
                                    value                = {props.kProposta === 'new' ? '' : props.kProposta}
                                    inputProps           = {{maxLength: 50}}
                                    disabled
                                />

                            </div>
                            <div className='flex-row'>

                                <div className='flexed-end add-autocomplete'>
                                    <Autocomplete
                                        disablePortal
                                        id                   = "cliente"
                                        options              = { props.aoClienti }
                                        value                = { props.aoClienti.find( (o:any) => o.id === props.kClienteSelected ) || ''}
                                        multiple             = { false }
                                        onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => {handleChange('cliente', newValue);} }
                                        sx                   = { { width: 300 } }
                                        renderInput          = { (params) => <TextField {...params} label="Cliente" variant="standard"/> }
                                        disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                    />
                                    <IconButton onClick={()=> openInsDialog({type:'Cliente', listaParent: props.aoClienti})} className='add-btn'>
                                        <AddIcon addClass='font-16'/> 
                                    </IconButton>
                                </div>

                                <div className='flexed-end add-autocomplete'>
                                    <Autocomplete
                                        disablePortal
                                        id                   = "agenzia"
                                        options              = { props.aoAgenzie }
                                        value                = { props.aoAgenzie.find( (o:any) => o.id === props.kAgenziaSelected ) || ''}
                                        multiple             = { false }
                                        onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { 
                                            handleChange('agenzia', newValue)
                                        } }
                                        sx                   = { { width: 300 } }
                                        renderInput          = { (params) => <TextField {...params} label="Agenzia" variant="standard"/> }
                                        disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                    />
                                    <IconButton onClick={()=> openInsDialog({type:'Agenzia', listaParent: props.aoAgenzie})} className='add-btn'>
                                        <AddIcon addClass='font-16'/>
                                    </IconButton>
                                </div>

                                <Autocomplete
                                    disablePortal
                                    id                   = "soggfatt"
                                    options              = { [...props.aoAgenzie, ...props.aoClienti] }
                                    value                = { [...props.aoAgenzie, ...props.aoClienti].find( (o:any) => ((o.id === props.kSoggFattSelected) && (o.TYPE === props.tipoSoggFattSelected)) ) || ''}
                                    multiple             = { false }
                                    onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.set_kSoggFattSelected( newValue?.id ); props.setTipoSoggFattSelected(newValue?.TYPE) } }
                                    sx                   = { { width: 300 } }
                                    groupBy              = { (option: any) => {return option.TIPO}}
                                    renderInput          = { (params) => <TextField {...params} label="Soggetto Fatturazione" variant="standard"/> }
                                    disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                />
                            </div>
                            <div className='flex-row'>

                                <Autocomplete
                                    disablePortal
                                    id          = "settore"
                                    options     = {props.settori}
                                    value       = {props.settori.find((el:any) => {return el.id === props.kSettore }) || ''}
                                    multiple    = {false}
                                    onChange={(event:SyntheticEvent, newValue: any ) => {handleChange('settore', newValue)}}
                                    /* disabled    = {props.proposalShouldBeDisabled || props.proposalTotalDisabled} */
                                    sx          = {{ width: 300 }}
                                    renderInput = {(params) =>
                                        <TextField {...params} label="Settore" variant="standard"/>
                                    }
                                />

                                <Autocomplete
                                    disablePortal
                                    id          = "categoria"
                                    options     = {[...props.categorie]}
                                    value       = {props.categorie.find((el:any) => {return el.id === props.kCategoria }) || ''}
                                    multiple    = {false}
                                    onChange={(event:SyntheticEvent, newValue: any ) => {props.setKCategoria(newValue?.id)}}
                                     disabled    = {!(props.kSettore) }/*|| (props.proposalShouldBeDisabled || props.proposalTotalDisabled)} */
                                    sx          = {{ width: 300 }}
                                    loading     = {props.loadingCategorie}
                                    renderInput = {(params) =>
                                        <TextField {...params} label="Categoria" variant="standard"/>
                                    }
                                />

                                <div className='proposal-date-range-container'>
                                    <LocalizationProvider dateAdapter={AdapterMoment} key="periodo-proposta">
                                        <DatePicker
                                            label            = "Inizio Periodo"
                                            format           = "DD/MM/YYYY"
                                            views            = {['year', 'month', 'day']}
                                            value            = { moment( props.oPeriodo?.sDataInizio || '', 'YYYYMMDD' ) }
                                            onChange         = { ( value ) => {props.set_oPeriodo({ ...props.oPeriodo, sDataInizio: moment(value).format('YYYYMMDD') })} }
                                            slotProps        = {{ textField: { variant: 'outlined' } }}
                                            className        = 'TextFieldDate smaller-date-field'
                                            disabled         = {/* props.proposalShouldBeDisabled || */ props.proposalTotalDisabled}
                                        />

                                        <DatePicker
                                            label            = "Fine Periodo"
                                            format           = "DD/MM/YYYY"
                                            views            = {['year', 'month', 'day']}
                                            value            = { moment( props.oPeriodo?.sDataFine || '', 'YYYYMMDD' ) }
                                            onChange         = { ( value ) => props.set_oPeriodo({ ...props.oPeriodo, sDataFine: moment(value).format('YYYYMMDD') }) }
                                            slotProps        = {{ textField: { variant: 'outlined' } }}
                                            className        = 'TextFieldDate smaller-date-field'
                                            disabled         = {/* props.proposalShouldBeDisabled || */ props.proposalTotalDisabled}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='flex-row'>
                                <div className='flexed-end add-autocomplete'>
                                    <Autocomplete
                                        disablePortal
                                        id                   = "referenteProgetto"
                                        options              = { props.referentiProgetto }
                                        value                = { props.referentiProgetto.find( (o:any) => o.id === props.kReferenteProgetto ) || ''}
                                        multiple             = { false }
                                        onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.setKReferenteProgetto( newValue?.id ) } }
                                        sx                   = { { width: 300 } }
                                        renderInput          = { (params) => <TextField {...params} label="Referente Progetto Esterno" variant="standard"/> }
                                        disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                    />
                                    
                                    {((props.kProposta !== 'new') && (props.kReferenteProgetto))&&(
                                        <IconButton className='copy-btn' onClick={ (event) => {handleCopyClipboard(event)}} >
                                            <CopyIcon addClass='font-16'/>
                                        </IconButton>)
                                    }

                                    <IconButton onClick={()=> openInsDialog({type:'Referente Progetto', listaParent: []})} className='add-btn'>
                                        <AddIcon addClass='font-16'/>
                                    </IconButton>
                                </div>

                                <Autocomplete
                                    disablePortal
                                    id                   = "talentmanager"
                                    options              = { props.aoTalentManagers }
                                    value                = { props.aoTalentManagers.find( (o:any) => o.id === props.kTalentManager ) || ''}
                                    multiple             = { false }
                                    onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.set_kTalentManager( newValue?.id ) } }
                                    sx                   = { { width: 300 } }
                                    renderInput          = { (params) => <TextField {...params} label="Talent Manager" variant="standard"/> }
                                    disabled             = {props.proposalTotalDisabled}
                                />

                                <Autocomplete
                                    disablePortal
                                    id                   = "refcommprogetto"
                                    options              = { props.aoRefCommProgetto }
                                    value                = { props.aoRefCommProgetto.find( (o:any) => o.id === props.kRefCommProgetto ) || ''}
                                    multiple             = { false }
                                    onChange             = { ( event:SyntheticEvent, newValue: AutocompleteClientsAgentsObject | null ) => { props.set_kRefCommProgetto( newValue?.id ) } }
                                    sx                   = { { width: 300 } }
                                    renderInput          = { (params) => <TextField {...params} label="Ref. Commerciale" variant="standard"/> }
                                    disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                />

                            </div>
                            <div className='flex-row' style={{flexWrap: 'nowrap'}}>

                                <FormGroup className='checkboxes-wrapper'>

                                    <FormControlLabel control={
                                        <Checkbox checked={props.bBudget}     onChange={(event) => props.set_bBudget(event.target.checked)} disabled = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}/>
                                    } label="Budget" />

                                    <FormControlLabel control={
                                        <Checkbox checked={props.fatturazioneDirettaTalent} onChange={(event) => props.setFatturazioneDirettaTalent(event.target.checked)} disabled = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}/>
                                    } label="Fatturazione Diretta Talent" />

                                </FormGroup>

                                <textarea 
                                    id='notes-textarea'
                                    className='notes-textarea' 
                                    rows={5} 
                                    cols={86} 
                                    maxLength={4000}
                                    value={props.note || ''} 
                                    onChange={(event) => props.setNote(event?.target.value)}
                                    disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                />

                            </div>
                            <div className='flex-roww'>
                                <TextField
                                    className='percentage-text-field-dn'
                                    id                   = "standard-basic"
                                    label                = "DN"
                                    variant              = "standard"
                                    value                = { !props.kAgenziaSelected ? '' : props.dn }
                                    onChange             = { (event) => {props.setDn( event.target.value )} }
                                    InputProps           = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }}
                                    inputProps={{sx:{textAlign:'right'}}}
                                    disabled             = { !props.kAgenziaSelected || (props.proposalShouldBeDisabled || props.proposalTotalDisabled)}
                                    error={(props.dn || props.dn === 0) ? !testNumberInputPercentagesTwoDecimals(props.dn) : props.dn === '' ? false : true} 
                                />

                                <TextField
                                    className='percentage-text-field-margine'
                                    id                   = "standard-basic"
                                    label                = "Margine Doom"
                                    variant              = "standard"
                                    value                = {props.margineDoomPerc}
                                    inputProps           = {{sx:{textAlign:'right'}}}
                                    onChange             = { (event) => {props.setMargineDoomPerc( event.target.value )} }
                                    InputProps           = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }}
                                    error={(props.margineDoomPerc || props.margineDoomPerc === 0) ? !testNumberInputPercentagesTwoDecimals(props.margineDoomPerc) : props.margineDoomPerc === '' ? false : true}
                                    disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                />

                                {props.bBudget&&(
                                    <TextField
                                        id                   = "standard-basic"
                                        label                = "Budget"
                                        variant              = "standard"
                                        value                = {props.budget}
                                        onChange             = { (event) => {if(inputOnlyNumbersWithComma(event.target.value))props.setBudget( event.target.value )} }
                                        inputProps={{sx:{textAlign:'right'}}}
                                        InputProps           = {{ endAdornment: <EuroIcon addClass='pad-left'/> }}
                                        disabled             = {props.proposalShouldBeDisabled || props.proposalTotalDisabled}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                
                <AddEntryDialog
                    {...{
                            openDialog,
                            handleCloseDialog,
                            nations: props.nations,
                            comuni: props.comuni,
                            
                            ...dialogParams,
                            getClienti: props.getAnagrafica({ url: 'clienti' }),
                            getAgenzie: props.getAnagrafica({ url: 'agenzie' }),
                            getReferentiProgetto: props.getAnagrafica({ url: 'refs-progetto' }),
                            kClienteSelected: props.kClienteSelected,
                            handleChange,
                            kPropStatus: props.kPropStatus
                        }
                    }
                />

                <Popper open={open} anchorEl={anchorEl} transition placement='top-start'>
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box className={'popper-box'} sx={{ p: 1, bgcolor: 'background.paper' }}>
                            Email Copiata!
                        </Box>
                    </Fade>
                    )}
                </Popper>
            </div>
        );
    }
}

export default Testata;